/* *************** MENU ************************* */

.content-wrapper.twoColumns {
    display: table;
    width: 100%;
}

.content-wrapper.twoColumns .site-menu {  /* MENU */
    display: table-cell;
    position: relative;
    width: 350px;
    min-width: 350px;
    background: #2f4155;
}

.content-wrapper.twoColumns .mainTable-wrapper {  /* CONTENT */
    display: table-cell;
    width: 100%;
    position: relative;
    background: #f5f5f5;
    padding: 0 0 20px 0;
}

.content-wrapper.twoColumns .site-menu .credit {
    position: absolute;
    bottom: 0;
    width: 100%;
}


.mainMenuTable {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: #2f4155;
    
}

.content-wrapper.twoColumns .site-menu header {
    text-align: center;
    padding: 30px 0 40px;
}

.content-wrapper.twoColumns .site-menu header img.logoClient {
    display: inline-block;
}

.content-wrapper.twoColumns .site-menu .mainMenuNav {
    width: 100%;
}

.content-wrapper.twoColumns .site-menu .mainMenuNav a,
nav.mobileNavbar .navbar-collapse .navbar-nav a
{
    width: 100%;
    display: block;
    border-bottom: 1px solid white;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    min-height: 52px;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.content-wrapper.twoColumns .site-menu .mainMenuNav a.is-active,
.content-wrapper.twoColumns .site-menu .mainMenuNav a:hover,
.content-wrapper.twoColumns .site-menu .mainMenuNav a:focus,
nav.mobileNavbar .navbar-collapse .navbar-nav a.is-active,
nav.mobileNavbar .navbar-collapse .navbar-nav a:hover,
nav.mobileNavbar .navbar-collapse .navbar-nav a:focus

{
    font-family: "fb_bold","helvetica","arial";
    background: #359ee5;
    border-bottom: none;
}

.content-wrapper.twoColumns .site-menu .mainMenuNav a img.bulletMainMenu,
nav.mobileNavbar .navbar-collapse .navbar-nav a img.bulletMainMenu
{
    margin: 0 0 0 20px;
}

.site-menu .fixedPosition {
    position: fixed;
    width: 350px;
    top: 0;
    bottom: 0;
}



nav.mobileNavbar {
    background: #2f4155 !important;
}

nav.mobileNavbar .navbar-collapse .navbar-nav {
    padding: 20px 0 10px 5%;
}

nav.mobileNavbar .navbar-brand {
    margin-left: 10px;
}


.NavInner {
    padding: 20px 2% 10px;
    background: white;
    position: relative;
    z-index: 10;

    -webkit-box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.2);
    box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.2);
}

.mainContent {
    padding: 20px 2%;
    position: relative;
}

.NavInner .searchNavInner input {
    background: #e9e9e9;

    border-radius:0 30px 30px 0 !important;
    -moz-border-radius: 0 30px 30px 0 !important;
    -webkit-border-radius: 0 30px 30px 0 !important; 
    padding-right: 20px;
    border-left: none;
}

.NavInner .searchNavInner button {

    border-radius:30px 0 0 30px !important;
    -moz-border-radius: 30px 0 0 30px !important;
    -webkit-border-radius: 30px 0 0 30px !important; 

    background: #e9e9e9;
    border: 1px solid #ced4da;
    border-right: none;
}

.NavInner .searchNavInner button:hover, 
.NavInner .searchNavInner button:focus {
    color: #359ee5;
}

.NavInner .userLogout .userCircle {
    color: #2f4155;
    font-size: 40px;
    display: inline-block;
    vertical-align: middle;
}

.NavInner .userCont {
    min-width: 100%;
    text-align: left;
}
.NavInner .userLogout .userCont .logOutBtn {
    padding: 0;
    /* display: inline-block; */
    vertical-align: middle;
    color: #2f4155;
    background: none;
    border: none;
    font-size: 20px;
    margin: 5px 20px 0 0;
    float: left;
}

.NavInner .userLogout .userCont .logOutBtn:hover,
.NavInner .userLogout .userCont .logOutBtn:focus {
    color: #359ee5;   
}


/* *************** END MENU ************************* */

/************ General STYLES *************/

.HomePage {
    padding: 20px 0 0 0;
}

h2.titStyle {
    font-family: "fb_regular","helvetica","arial";
    color: #359ee5;
    font-size: 25px;
    border-right: 2px solid #359ee5;
    padding: 0 20px 0 0;
    line-height: 1;
    margin: 0 0 20px 0;
}

.blueH2 {

    font-family: "fb_regular","helvetica","arial";
    color: #359ee5;
    font-size: 25px;
    line-height: 1;
    margin: 0 0 20px 0;
}

.goBackCustomBtn {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0 0 0 10px;
    background: transparent;
    
}

.goBackCustomBtn:hover,
.goBackCustomBtn:focus
{
    color: #359ee5;
    text-decoration: underline;
}

input.customInput {
    font-family: "fb_regular","helvetica","arial";
    color: #2f4155;
}

.customInput .inputPrepend {
    border-radius: 5px 0 0 5px !important;
    margin-right: -1px;
}

.customInput .inputPrependLabel span {
    border-radius: 0px 5px 5px 0 !important;
}


select.customSelect {
    background: url('../img/icons/SVG/downSelect.svg') 10px center no-repeat white;
    background-size: 12px;
    margin: 0px auto 0;
    font-family: "fb_regular","helvetica","arial";
    color: #2f4155;
    padding: 0 10px 0 32px;
}

.customInput .selectPrepend, .selectDefault {
    background: url('../img/icons/SVG/downSelect.svg') 10px center no-repeat white;
    background-size: 12px;
    padding-left: 25px;
}

.selectDefault {
    padding-left: 35px;
}

.formContainerAdmin .divBoostrap {
    padding: 0 .5%;
    margin-bottom: 15px;
}

.customInput.textArea {
    position: relative;
}

.customInput.textArea label {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    margin: 5px 5px 0 0;
    width: 100px;
    color: grey;
    text-align: left;
}

.customInput.textArea textarea {
    padding-right: 115px;
}

.customInput .dateTimeCont {
    display: table;
    width: 100%;
    background: white;
}

.customInput .dateTimeCont .customPrepend {
    display: table-cell;
    background: #e9ecef;
    border: 1px solid #ced4da;
    padding: .375rem 0.75rem;
    font-size: 16px;
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
}

.customInput .dateTimeCont .react-datepicker-wrapper {
    display: table-cell;
}

.addBtn {
    float: left;
    background: none;
    color: #2f4155;
    font-family: "fb_regular","helvetica","arial";
    font-size: 16px;
    margin: 10px 0 0 10px;
    border: none;
}

.addBtn:hover,
.addBtn:focus {
    color: white;
}

.toTasksBtn {
    float: left;
    background: none;
    color: #212529;
    font-family: "fb_regular","helvetica","arial";
    font-size: 16px;
    margin: 0px 0 0 0px;
    border: none;
    text-decoration: none;
}

.toTasksBtn:hover,
.toTasksBtn:focus {
    text-decoration: underline;
    color: #359ee5;
}

.addBtnGreen {
    float: left;
    background: #3D8575;
    color: white;
    font-family: "fb_regular","helvetica","arial";
    font-size: 16px;
    margin: 0px 0 0 10px;
    border: none;
    border-radius: 20px;
    padding: .25rem 20px;    
}

.addBtnGreen:hover,
.addBtnGreen:focus
{
    background: #2F4155;
}

.addBtnGreen img {
    width: 18px;
    margin: 3px 0 0 10px;
    display: inline-block;
    vertical-align: top;
}




.deletteBtn {
    opacity: 0.5;
}

.addBtn.addSection {
    float: none;
    margin: 0px 10px 0 0;
}





.tableDisplay {
    width: 100%;
    display: table;
    /* background: white; */
    text-align: center;
}

.tableDisplay .trTable {
    display: table-row;
}

.tableDisplay .trTable.titlesTr div {
    display: table-cell;
    background: white;
    font-family: "fb_regular","helvetica","arial";
    line-height: 1.2;
    font-size: 17px;
    color:grey;
    padding: 5px 0 ;
    border-left: 1px solid #bebebe;
    background: #e9ecef;
    
}


.tableDisplay .trTable.titlesTr div:first-child {
    border-radius: 0 5px 5px 0;
}

.tableDisplay .trTable.titlesTr div:last-child {
    border-radius: 5px 0 0 5px;
}

.tableDisplay .trTable.titlesTr div:last-child {
    border-left: 0;
}

.tableDisplay .trTable .tdTable {
    display: table-cell;
    vertical-align: middle;
}

.tableDisplay .trTable  .vSpace {
    
    padding: 4px 0;
}

.tableDisplay .trTable.contentTds {
    background: #e6e6e6;
}

.tableDisplay .trTable.contentTds > div {
    display: table-cell;
    vertical-align: middle;
    /* border-bottom: 1px solid #bebebe; */
}

.tableDisplay .trTable.contentTds:nth-child(odd) > div {
    background: white;
}

.tableDisplay .trTable.contentTds button.enterBtn {
    width: 100%;
    border: none;
    background: none;
    padding: 10px 0;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.tableDisplay .trTable.contentTds button.enterBtn i {
    color: #359ee5;
    font-size: 15px;
    margin: 0 10px 0 0;
} 

.tableDisplay .trTable.contentTds button.enterBtn:hover,
.tableDisplay .trTable.contentTds button.enterBtn:focus {
    color: white;
    background: #359ee5;
}

.tableDisplay .trTable.contentTds button.enterBtn:hover i,
.tableDisplay .trTable.contentTds button.enterBtn:focus i {
    color: white;
}


.changeBtn {
    margin: 0 0 0 -15px;
}

ul.showData {
    margin: 0 auto;
    background: white;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
}

ul.showData li {
    padding: 5px 10px;
    font-size: 15px;
}



.SearchAutocomplete .sc-bwzfXH  {
    padding: 0 10px 0 40px;

}

.SearchAutocomplete .sc-bdVaJa svg {
    display: none;
}


.divAutocomplete .customInput {
    display: table;
    width: 100%;
    position: relative;
    z-index: 10;
}

.divAutocomplete .customInput .inputPrependLabel {
    display: table-cell;
    vertical-align: top;
}
.divAutocomplete .customInput .SearchAutocomplete {
    display: table-cell;
    vertical-align: top;
    width: 100%;

}

.divAutocomplete .customInput .SearchAutocomplete input {
    color: grey;
}

.divAutocomplete .customInput .SearchAutocomplete .wrapper {
    border-radius: 5px 0 0 5px;
}

.divAutocomplete .customInput .SearchAutocomplete .wrapper .sc-bwzfXH {
    padding: 0;
    min-height: 36px;
    
}

.divAutocomplete .customInput .SearchAutocomplete:hover .wrapper,
.divAutocomplete .customInput .SearchAutocomplete:focus .wrapper{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}


.customInput.inputBorderGrey .inputPrependLabel span,
.customInput.inputBorderGrey .form-control
{
    border-color: black;
    
}

.customInput.borderColorGreen .inputPrependLabel span,
.customInput.borderColorGreen .form-control
{
    border-color: green;
    
}

.loader {
    position: fixed;
    top: 20%;
    width: 100px;
    right: 50%;
    margin: 0 -50px 0 0;
    display: none;
    z-index: 50;

}

.loader.active {
    display: block;
}

.smallBtnBlack {

    background: none;
    border: none;
    float: left;
    font-size: 14px;
}

.smallBtnBlack:hover,
.smallBtnBlack:focus
{
    text-decoration: underline;
}

/************ END General STYLES *************/

.HomePage .DataSummary {
    margin: 0 0 20px 0;
}

.QuadGraph {
    background: white;
    width: 100%;
    text-align: center;
    /* min-height: 290px; */
    padding: 10px 0 0 0;
    margin: 0 0 25px 0;
    
}

.QuadGraph header {
    
    border-bottom: 1px solid #2f4155;
    width: 70%;
    display: table;
    min-height: 75px;
    margin: 0 auto 20px;
}

.QuadGraph header h3 {
    display: table-cell;
    min-height: 75px;
    vertical-align: middle;
    font-family: "fb_regular","helvetica","arial";
    line-height: 1.2;
    font-size: 20px;
    color: #2f4155;
}

.QuadGraph .graphCont {
    padding: 10px 60px 30px;
}

.HomePage .DataSummary {
    margin: 0 0 20px 0;
}

.HomePage .Tasks {
    margin: 0 0 20px 0;
}

.HomePage .Tasks table {
    background: white;
}

.HomePage .Tasks table tr th {
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
}

.HomePage .Tasks table tr th button {
    border: none;
    padding: 0;
    color: rgb(226, 226, 226);    
    background: none;
}

.HomePage .Tasks table tr th button:hover,
.HomePage .Tasks table tr th button:focus,
.HomePage .Tasks table tr th button.active {
    color: #359ee5;
}

.HomePage .Tasks table tr th button i {
    font-size: 14px;
}

.HomePage .Tasks table tr td {
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}

.HomePage .Tasks table tr.yellowTrClass td:nth-child(1) {
    border-right: 4px solid #fccf14;
}

.HomePage .Tasks table tr.redTrClass td:nth-child(1) {
    border-right: 4px solid #ff2525;
}

.HomePage .Tasks table tr.greenTrClass td:nth-child(1) {
    border-right: 4px solid #02b201;
}

.HomePage .Tasks table tr select {
    padding: 0;
    text-align: center;
    border: none;
    font-family: "fb_regular","helvetica","arial";
    font-size: 18px;
    background: url('../img/icons/SVG/downSelect.svg') left center no-repeat;
    background-size: 8px;
    
    padding: 0 0 0 20px;
    margin: 0px auto 0;
}



.HomePage .Tasks table tr.yellowTrClass td.selectTd  {
    background-color: #fff5cd;
    width: 90px;
}
.HomePage .Tasks table tr.redTrClass td.selectTd {
    background-color:  #ff8d8d45;
}
.HomePage .Tasks table tr.greenTrClass td.selectTd {
    background-color:  #caf7ca;
}

.HomePage .Tasks table td.commentsTd {
    width: 350px;
    text-align: right;
}

.ClientsPage {
    padding: 0 5%;
}

.clientsList button.clientDataName {
    display: inline-block;
    margin: 0 0 10px 0;
    text-decoration: none;
    color: black;
    border: none;
    background: none;
}

.clientsList div button.offBtn {
    display: inline-block;
    opacity: 0.5;

}



.clientsList button.clientDataName:hover,
.clientsList button.clientDataName:focus {
    color: #359ee5;
    text-decoration: underline;
}



.clientScreen h2 {
    display: inline-block;
}

.clientScreen section.headerSec {
    margin: 0 0 20px 0;
}

.clientScreen section.headerSec .info ul  {
        display: table;
}

.clientScreen section.headerSec .info ul li {
    display: table-cell;
    margin: 0 0 10px 0;
    vertical-align: middle;
    padding: 0 0 0 20px;
}

.clientScreen section.headerSec .info ul li.statusChange strong {
    display: inline-block;
}
.clientScreen section.headerSec .info ul li.statusChange select {
    display: inline-block;
    width: 240px;
    margin: 0 10px 0 0;
}

.clientScreen section.headerSec .info ul li strong {
    margin: 0 0 0 5px;
}

.clientScreen section.headerSec .info ul li.listSpan span {
    border-left: 1px solid rgb(200, 200, 200);
    padding: 0 0 0 5px;
    margin: 0 0 0 5px;
}



.clientScreen section.headerSec .info ul li.listSpan span:last-child {
    border-left: 0;
}


.clientScreen .row.searchOnClients {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 10;
}

.clientScreen .row.searchOnClients .col-9 {
    padding-left: 5px;
}

.clientScreen .row.searchOnClients .SearchAutocomplete .igZibq {
    min-height: 37px;
}

.clientScreen .row.searchOnClients .SearchAutocomplete .wrapper {
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.reportClient {
    padding: 30px 0 50px 0;
}

.reportClient .tableComments .trTable.titlesTr .tdTable:nth-child(2) {
    padding: 0 20px;
}


.seniorsTableContact {
    width: 100%;
    display: table;
    /* background: white; */
    text-align: center;
}

.seniorsTableContact .trTable {
    display: table-row;
}

.seniorsTableContact .trTable.titlesTr div {
    
    background: white;
    font-family: "fb_regular","helvetica","arial";
    line-height: 1.2;
    font-size: 17px;
    color:grey;
    padding: 5px 0 ;
    border-left: 1px solid #bebebe;
    background: #e9ecef;
    
}


.seniorsTableContact .trTable.titlesTr div:first-child {
    border-radius: 0 5px 5px 0;
}

.seniorsTableContact .trTable.titlesTr div:last-child {
    border-radius: 5px 0 0 5px;
}

.seniorsTableContact .trTable.titlesTr div:last-child {
    border-left: 0;
}

.seniorsTableContact .trTable .tdTable {
    display: table-cell;
    vertical-align: middle;
}

.seniorsTableContact .trTable  .vSpace {
    padding: 4px 0;
}

.seniorsTableContact .trTable.contentTds > div {
    display: table-cell;
    vertical-align: middle;
    /* border-bottom: 1px solid #bebebe; */
}


.seniorsTableContact .trTable.contentTds > div:last-child {
    border-left: 0;
}

.seniorsTableContact .trTable.contentTds > div .customInput {
    
    background: #f5f5f5;
    padding: 3px 2% 3px ;
}

.seniorsTableContact .trTable.contentTds > div input {
    
    text-align: center;
    border-radius: 5px !important;
    
    background: white;

    
}

section.medicalStatus {
    margin: 20px 0 0 0;
}






.WorkersPage {
    padding: 0 5%;
}

.workersList button.clientDataName {
    display: inline-block;
    margin: 0 0 10px 0;
    text-decoration: none;
    color: black;
    border: none;
    background: none;
}

.workersList div button.offBtn {
    display: inline-block;
    opacity: 0.5;

}



.workersList button.clientDataName:hover,
.workersList button.clientDataName:focus {
    color: #359ee5;
    text-decoration: underline;
}

.workersList h2 {
    display: inline-block;
 
}
.workerScreenSec .titStyle.firstH2 {
    margin: 0 0 30px 0;
}
.workerScreenSec .divBoostrap.address {
    margin: 0 0 50px 0;
}

.workerScreenSec .divBoostrap.CountryofBirth::after {
    content: '';
    width: 100%;
    display: block;
    margin: 30px 0 0 0;
}

.workerScreenSec .divBoostrap.magnetCard::after {
    content: '';
    width: 100%;
    display: block;
    margin: 30px 0 0 0;
}

.workerScreenSec .filesWorker {
    margin: 20px 0 50px 0;
}

.workerScreenSec .RecommendsWorker {
    margin: 20px 0 0px 0;
}




.PlacementDataHistory {

    padding: 0px 5px 20px;
}

header.newTask {
    text-align: left;
}

header.newTask .newTaskBtn {
    background: #2f4155;
    color: white;
    border: none;
    padding: 5px 20px;
    font-size: 16px;
    border-radius: 20px;
}

header.newTask .newTaskBtn:hover,
header.newTask .newTaskBtn:focus {
    background: black;;
}


.newUserBtn {
    background: #2f4155;
    color: white;
    border: none;
    padding: 5px 20px;
    font-size: 16px;
    border-radius: 20px;
    float: left;
}

.newUserBtn:hover,
.newUserBtn:focus {
    background: black;;
}




header.newTask .newTaskBtn:first-child {
    margin: 0 0 0 10px;
}

.newTaskModal .modal-body {
    padding-top: 40px;
    padding-bottom: 40px;
}

.newTaskModal .sentForm h2 {
    text-align: center;
    color: darkgreen;
}



.workerScreen .linkedWorker {
    margin: 30px 0 40px 0;
}

.workerScreen .linkedWorker h4 {
    color: red;
}


.filesWorker .formFileSection > div > div {
    display: inline-block;
    width: 550px;
    padding: 0 10px 0 0;
}

.filesWorker .formFileSection .progress {
    /* display: block; */
    /* width: inherit; */
    width: 640px;
    padding: 0;
    margin: 5px 0px 0 0;
    background: transparent;
}

.filesWorker .formFileSection .informDiv {
    padding: 0 0 0 5px;
}

.filesWorker .filePickUpBtn {
    background:  #359ee5;
    border-color: #359ee5;
}

.filesWorker .filePickUpBtn:hover,
.filesWorker .filePickUpBtn:focus {
    background: #2f4155;
}

.filesWorker .btn.submitValidationFormBtn {
    display: inline-block;
    width: 150px !important;
    padding: 3px 20px;
    margin: 0 15px 0 0;
}

.filesWorker h3 {
    margin: 10px 0 10px 0;
    font-size: 20px;
}

.filesWorker p.fileTypesText {
    font-size: 16px;
}

.filesWorker .validatorInputError {
    margin: 0 0 15px 0;
}

.filesWorker .errorUpload {
    color: red;
    font-family: "fb_regular","helvetica","arial";
}

.filesWorker .okUpload {
    color: green;
    font-size: 20px;
    font-family: "fb_regular","helvetica","arial";
}

.filesWorker ul {
    margin: 10px 0 0 0;
    display: inline-block;
    padding: 5px 20px;
}

.filesWorker ul li {
    display: inline-block;
    min-width: 50px;
    padding: 20px 10px;
}

.filesWorker .fileA {
    color: black;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
}

.filesWorker .fileA i {
    color: #359ee5;
    font-size: 20px;
    display: block;
    margin: 0 0 5px 0;
}

.filesWorker .fileA:hover span,
.filesWorker .fileA:focus span
{
    color: #359ee5;
    text-decoration: underline;
}

.filesWorker .fileA:hover i,
.filesWorker .fileA:focus i
{
    color: black;
    
}
.filesWorker.noReka ul {
    padding: 0;
    background: none;
    border: none;
}

.filesWorker.noReka ul li {
    background: white;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    margin: 0 0 0 5px;
    padding: 20px 20px;
}




.defaultDisplayTable {
    background: white;
}

.defaultDisplayTable  tr th {
    text-align: center;
    font-size: 18px;
}

.defaultDisplayTable tr td {
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}


.FilesWrapperAll {
    margin: 50px 0 0 0;
}



button.showHidden {
    background: #2f4155;
    color: white;
    border: none;
    padding: 5px 20px;
    font-size: 16px;
    border-radius: 20px;
    float: left;
}

button.showHidden:hover,
button.showHidden:focus {
    background: black;;
}

.inserUserModal {
    padding: 0 5%;
}

.inserUserModal .formInput {
    margin: 0 0 20px 0;
}

.inserUserModal .validatorInputError {
    margin-bottom: 0;
}

.inserUserModal .submitValidationFormBtn {
    width: 50% !important;
    margin: 20px auto 0;
}

.inserUserModal .input-group-text
 {
    min-width: 100px;
    border-radius: 0 5px 5px 0;
}

.inserUserModal input,
.inserUserModal select
{
    border-radius: 5px 0 0 5px !important;
    margin: 0 -1px 0 0;

}

.inserUserModal p.okUserInsert {
    color: green;
}


.UsersPage .tableDisplay .trTable.contentTds > div {
    padding: 10px 0;
}

.UsersPage .tableDisplay .trTable.contentTds > div.vSpace {
    padding: 5px 0;
}


.newReport {
    margin: 0 15px 15px 0;
    clear: both;
    
}

.newReport .row.formContainerAdmin {
    float: right;
}
.addRecordBtn {
    float: right;
    margin: 0 20px 0 0;
}

.matchList {
    width: 100%;
}

.matchList .tableList {
    display: table;
    width: 100%;
}

.matchList .tableList .rowTable {
    display: table-row;
}

.matchList .tableList .rowTable .tdTable {
    display: table-cell;
    text-align: center;
    font-size: 18px;
}

.matchList .tableList .rowTable .tdTable:nth-child(1),
.matchList .tableList .rowTable .tdTable:nth-child(2)
{
    width: 35%;
}

.matchList .tableList .rowTable .tdTable:nth-child(3) {
    width: 25%;
    text-align: center;
}

.matchList .tableList .rowTable .tdTable:nth-child(4) {
    width: 5%;
    text-align: left;
    padding: 0 0 0 5px;
    font-size: 13px;
}

.matchList .rowTable.titles .tdTable {
    font-weight: bold;
    font-family: "fb_regular","helvetica","arial";
}


.matchList .dropdown {
    margin: 20px 0 20px 0;
    text-align: center;
}


.matchList .dropdown .card {
    border: none;
}

.matchList .dropdown .card-body {
    padding: 0 0 20px 0;
}

.matchList .dropdown .card-header {
    padding: 0;
    border: none;
}

.matchList .dropdown .tdTable .AccordionTd:nth-child(odd) .card-header {
    background: white;
}
.matchList .dropdown .tdTable .AccordionTd:nth-child(even) .card-header,
.matchList .dropdown .tdTable .AccordionTd:nth-child(even) .card-body
{
    background: #e6e6e6;
}




.matchList .dropdown .AccordionTd .btn.btn-link {
    width: 100%;
    display: table;
    padding: 15px 0;
    text-decoration: none;
    color: black;
    border: none;
    box-shadow: none;
}

.matchList .dropdown .results {
    width: 100%;
}

.matchList .dropdown .results ul {
    width: 100%;
}

.matchList .dropdown .results ul li {
    display: inline-block;
    margin: 0 0 0 20px;
    font-family: "fb_regular","helvetica","arial";
    font-size: 16px;
}

.matchList .dropdown .results ul li img {
    width: 15px;
    margin: 0 0 0 5px;
}


.matchList .viewClienBtn {
    color: #359ee5;
    font-family: "fb_regular","helvetica","arial";
    text-decoration: none;
    font-size: 14px;
    width: 100px;
    display: inline-block;
}

.matchList .viewClienBtn:hover,
.matchList .viewClienBtn:focus {
    color: black;
    text-decoration: underline;
    
}


.pannelSearch {
    padding: 20px 2% 10px;
    background: white;
    position: relative;
    z-index: 10;
    box-shadow: 0px 4px 5px 1px rgb(0 0 0 / 20%);
    margin: 10px 0 20px 0;
}

.pannelSearch .inputSearchCont {
    position: relative;
}

.pannelSearch .inputSearchCont svg {
    position: absolute;
    opacity: .5;
    top: 0;
    margin: 7px 11px 0 0;
    z-index: 12;
}

.pannelSearch .inputSearchCont .searchInput {
    border: 1px solid #bcbcbc;
    border-radius: 20px !important;
    padding: 6px 50px;
    width: 60%;
}

.pannelSearch .inputSearchCont .searchInput:hover,
.pannelSearch .inputSearchCont .searchInput:focus {
    border-color: black;
    border-radius: 20px;
}

.clients .NavInner .SearchAutocomplete,
.foreignWorkers .NavInner .SearchAutocomplete,
.homeVisits .NavInner .SearchAutocomplete
{
    display: none;
}


.accordionSearch .accordion {
    margin: 10px 0 0 0;
}

.accordionSearch .accordion .card {
    border: none;
    background: white;
}

.accordionSearch .accordion .card .card-header {
    padding: 0;
    border: none;
    background: white;
    

}

.accordionSearch .accordion .card .card-header button {
    color: #359EE5;
    text-decoration: none;
    font-family: "fb_regular","helvetica","arial";
}

.sendSmsPanel .inputsGroup {
    margin: 0 0 20px 0;
}


.sendSmsPanel .inputsGroup .line {
    display: inline-block;
    margin: 0 0 0 10px;
}

.sendSmsPanel .inputsGroup .line label {
    margin: 0 10px 0 0;
    font-size: 19px;
}


.sendSmsPanel .inputsGroup .line button img {
    width: 20px;
    vertical-align: top;
    margin: 5px 0 0 0;
}

.sendSmsPanel .inputsGroup .line button:hover span,
.sendSmsPanel .inputsGroup .line button:focus span {
    text-decoration: underline;
}

.sendSmsPanel .inputsGroup .line button span {
    font-size: 19px;
    margin: 0 10px 0 0;
}


.sendSMSModalBody p.alertP {
    font-family: "fb_regular","helvetica","arial";
    font-size: '16px';
    margin: '0';
}

.sendSMSModalBody .fileTypesText {
    margin: 5px 0px 0 0;
    font-size: 16px;
    text-align: center;
    
}

.sendSMSModalBody .filePickUpBtn {
    display: block;
    margin: 40px auto 0;
    width: 200px;
    font-size: 20px;

}

.sendSMSModalBody .fileName {
    text-align: center;
    margin: 20px 0 0 0;
    color: #0e6ffd;
}

.sendSMSModalBody .btnNextStep {
    margin: 20px auto 0;
    display: block;
    float: left;
}

.sendSMSModalBody p.errorUpload {
    color: red;
    text-align: center;
    font-family: "fb_regular","helvetica","arial";
}

.sendSMSModalBody  .smsTest h2 {
    color: green;
}

.sendSMSModalBody  .smsTest p {
    font-family: "fb_regular","helvetica","arial";
    margin: 0 0 20px 0;
}

.sendSMSModalBody .sendTruesms {
    padding: 0 20%;
}

.savedWindow {
    background: #008000;
    color: white;
    padding: 5px 20px;
    letter-spacing: 1px;
    position: fixed;
    width: 210px;
    top: 139px;
    right: 66px;
    font-size: 16px;
    z-index: 90;
    margin: 0 0 0 71px;
    border-radius: 20px;
}

/* .configPage .formContainerAdmin {
    padding: 0 0 0 20%;
} */


.testDrag {
    width: 400px;
    margin: 50px auto;
}

.testDrag ul {
    width: 100%;
}

.testDrag ul li {
    display: block;
    width: 100%;
    display: block;
    text-align: right;
    clear: both;
    margin: 0 0 10px 0;
    min-height: 90px;
    background: white;
    box-shadow: 0px 4px 5px -1px rgb(0 0 0 / 20%);
}

.testDrag ul li p {
    padding: 5px 20px 0 0;
    float: right;
}

.testDrag ul li .characters-thumb {
    float: right;
    width: 90px;
    
}

.testDrag ul li img {
    width: 100%;
    padding: 5px;
    
}

.newTaskModal .huklGi {
    width: 100%;
    z-index: 20;
    margin: 0 0 10px 0;
}

.newTaskModal .sc-bwzfXH.igZibq {
    border: 1px solid #ced4da;
    height: 38px;
    min-height: 38px;
    border-radius: 5px;
    position: relative;
    z-index: 20;
}

.newTaskModal .sc-bdVaJa.evwHCa {
    position: relative;
    background: white;
    border: 1px solid grey;
    margin: 5px 0 0 0;
    border-radius: 5px;
    padding: 10px;
}

.newTaskModal .huklGi .wrapper {
    width: 95%;
    box-shadow: none !important;
    border: none;
}

.newTaskModal .wrapper::after {
    display: none;
}

.newTaskModal .igZibq > svg {
    margin: 6px 9px 5px 8px;
}


.newTaskModal .react-datepicker__tab-loop {
    z-index: 21;
    position: relative;   
}

.newTaskModal .clearSearch {
    width: 100%;
    text-align: left;
    margin: 0 0 0px 0;
    line-height: 1;
    margin: -10px 0 20px 0;
}

.newTaskModal .clearSearch button {
    background: none;
    border: none;
    font-size: 15px;
    color: #0d6efd;
    text-decoration: underline;
    padding: 0;
    margin: 0;
}



.coordinatorLinksModal .modal-body {

    padding: 0;

}

.CoordinatorPopup {
    padding: 0px;
    background: #efefef;
}

.coordinatorLinksModal .modal-body > .btn {
    position: absolute;
    left: 10px;
    top: 10px;
}

.CoordinatorPopup header {
    
    background: white;
    padding: 20px 20px 20px;
    /* border-bottom: 1px solid rgb(179, 179, 179); */
    
}

.CoordinatorPopup h2 {
    margin: 0;
}

.CoordinatorPopup h4.done {
    color: green;
    text-decoration: none;

}

.CoordinatorPopup h3 {
    font-size: 25px;
    margin: 0 0 30px 0;
    text-decoration: underline;
}

.CoordinatorPopup .containerModal {
    padding: 30px 40px;
}

.CoordinatorPopup ul li {
    font-size: 24px;
}

.CoordinatorPopup ul li span {
    vertical-align: middle;
}

.CoordinatorPopup ul li button {
    padding: 0;
    border: none;
    background: none;
    vertical-align: middle;
    margin: 0 0 2px 7px;
    
}


.CoordinatorPopup ul li i {
    font-size: 14px;
    vertical-align: middle;
}



.CoordinatorPopup ul li button:hover i {
    font-size: 18px;
    color: red;
}

.CoordinatorPopup ul {
    margin: 0 0 20px 0;
}

.modalErrors .errorCheck p {
    color: red;
    text-align: center;
    font-weight: bold;
}


.modalErrors .modal-header {
    background: rgb(156, 1, 1);
    color: white;
    text-align: center;
}

.modalErrors .modal-header .modal-title {
    width: 100%;
    padding: 0 20px;
}


.reportClient .tableComments .contentTds div {
    padding: 0 2px;
}


.reportClient .tableComments .contentTds div p {
    /* background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px; */
    margin: 0 0 4px 0;
    font-size: 16px;
    text-align: center;
}

.reportClient  .tableComments.SeniorContacts .trTable .vSpace {
    background: #f5f5f5;
}

.reportClient .tableComments.SeniorContacts .trTable div:nth-child(3) {
    width: 100%;
}

.reportClient .tableComments.SeniorContacts .trTable div:nth-child(3) p {
    text-align: right;
    padding: 0 10px;
}


.reportClient .insertComment {
    margin: 10px 0 40px 0;
}

.reportClient .insertComment input {
    display: inline-block;
    width: 87%;
    margin: 0;
}

.reportClient .insertComment button {
    float: left;
    margin: 0;
    width: 12%;
}


.homeVisitsClient {
    margin: 0px 0 0px;
}

.homeVisitsClient .tableComments {
    margin: 20px 0 0 0;
}



.homeVisitsClient .tableComments .contentTds div {
    padding: 0 2px;
}


.homeVisitsClient .tableComments .contentTds div p {
    /* background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px; */
    margin: 0 0 4px 0;
    font-size: 16px;
    text-align: center;
}

.homeVisitsClient  .tableComments.SeniorContacts .trTable .vSpace {
    background: red;
}


.homeVisitsClient .tableComments.SeniorContacts .trTable div:nth-child(4) {
    width: 100%;
}

.homeVisitsClient .tableComments.SeniorContacts .trTable div:nth-child(4) p {
    text-align: right;
    padding: 0 10px;
}

.homeVisitsClient .tableComments.SeniorContacts .trTable div:nth-child(5) p {
    width: 60px;
    text-align: center;
    padding: 5px 0;
}


.homeVisitsClient  .tableComments.SeniorContacts .trTable img {
    margin: 0;
    width: 60px;
    display: block;
}

.homeVisitsClient .insertComment {
    margin: 10px 0 0 0;
}

.homeVisitsClient .insertComment input {
    display: inline-block;
    width: 84%;
    margin: 0;
}

.homeVisitsClient .insertComment button {
    float: left;
    margin: 0;
    width: 15%;
    
}


.homeVisitsPage .clientDataVisit {
    margin: 50px 0 30px 0;
}

.homeVisitsPage .clientDataVisit a.jumpTo {
    color: black;
    font-size: 16px;
    margin: 0 10px 0 0;
    text-decoration: none;
}

.homeVisitsPage .clientDataVisit a.jumpTo:hover,
.homeVisitsPage .clientDataVisit a.jumpTo:focus
{
    text-decoration: underline;
}


.homeVisitsPage .seniorsTableContact .trTable.titlesTr div {
    background: white;
}


.homeVisitsPage  .tableComments.SeniorContacts .trTable img {
    margin: 0;
    width: 60px;
}

.homeVisitsPage .insertNewVisit {
    margin: 20px 0 0 0;
}

.homeVisitsPage .signContainer {
    background: white;
    padding: 20px 0;
    position: relative;
    margin: 30px 0 0 0;
    border: 1px solid rgb(228, 228, 228);
    background: rgb(228, 228, 228);

}

.homeVisitsPage .signContainer canvas {
    border: 1px solid rgb(228, 228, 228);
}

.homeVisitsPage .signContainer button.eraseBtn {
    float: right;
    width: 50px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
}

.homeVisitsPage .signContainer button.eraseBtn:hover,
.homeVisitsPage .signContainer button.eraseBtn:focus {
    color: red;
}

.homeVisitsPage .signContainer h3 {
    color: black;
    font-size: 22px;
}

.homeVisitsPage .sendForm {
    margin: 30px 0 0 0;
}

.homeVisitsPage .searchClients {
    position: relative;
    z-index: 15;
}


.header-scroll .pannelFixed {
    position: fixed;
    width: 90%;
    padding: 0 0 0 20%;
    top: 0;
    background: white;
    z-index: 10;
    
    border-bottom: 1px solid black;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.header-scroll .pannelFixed .sendSmsPanel {
    padding: 0 10px 0 0;
}


.reportClient .allCommntsBtn {
    float: left;
    background: transparent;
    text-decoration: underline;
}

.reportClient .allCommntsBtn:hover,
.reportClient .allCommntsBtn:focus
{
    background: white;
}

.modalInvoices span.buttonsCont {
    display: block;
    margin: 5px 0  0 0;
    padding: 0 !important;
}

.modalAllComments .modal-header h2 {
    margin: 0 10px 0 0;
}

.modalAllComments .modal-body ul {

    height: 600px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 10px 0 0;

}

.modalAllComments ul li p {
    margin: 0;
}

.modalAllComments ul li {
    margin: 0 0 15px 0;
    background: rgb(236, 236, 236);
    padding: 20px 15px;
    border-bottom: 1px solid #c2c2c2;
    width: 98%;

}


.accordionSec {
    margin: 20px -.5% 0 0;
    width: 101%;
}

.accordionSec .card-header {
    padding: 0;
}

.accordionSec .card-body {
    padding: 10px 20px;
}

.accordionSec .card-header button {
    background: white;
    width: 100%;
    text-align: right;
    text-decoration: none;
    padding: 10px 10px;

    background: url('../img/icons/SVG/downSelect.svg') 10px center no-repeat white;
    background-size: 12px;
    margin: 0px auto 0;
    font-family: "fb_regular","helvetica","arial";
    color: #2f4155;
}

.accordionSec .card-header button:hover h2,
.accordionSec .card-header button:focus h2
{
    color: #359ee5;
    
}

.accordionSec .card-header button h2 {
    border-right: none;
    padding: 0 20px 0 0;
    margin: 0;
    font-size: 18px;
    color: #2f4155;
}

.accordionSec .accordion .card {
    background: transparent;
    margin: 0 0 10px 0;
    
}

.accordionSec .accordion .card-body > section {
    padding-bottom: 10px;
}



.accordionSec .accordion .card .card-body h2.titStyle  {
    display: none;
}

.accordionSec .accordion .card .card-body .contactsClient {
    margin: 30px 0 0 0;
}




.FinanceIndex h2 {
    font-size: 22px;
    margin: 0 0 20px 0;
}


.FinanceIndex .navButtons .buttonCont {
    text-align: center;
    
}

.FinanceIndex .navButtons.endPays .buttonsCont {
    margin: 10px 0 30px 0;
}

.FinanceIndex .navButtons.endPays .DropdownButtonsDownload.show a,
.FinanceIndex .navButtons.endPays .DropdownButtonsDownload.show button {
    margin: 0 10px 0px;
    font-size: 16px;
    font-family: "fb_regular","helvetica","arial";
}

.FinanceIndex .navButtons.endPays .DropdownButtonsDownload.show a:hover,
.FinanceIndex .navButtons.endPays .DropdownButtonsDownload.show button:hover {
    background: #565e64;
    color: white;
    
    
}


.FinanceIndex .navButtons.endPays .buttonsCont > div {

        display: block;
        margin: 20px 10px;
}

.FinanceIndex .navButtons.endPays .buttonsCont > div > button {
    width: 200px;
}

.FinanceIndex .invoices {
    margin: 20px 0 0 0;
}

.FinanceIndex .dataPay .divBoostrap {
    margin: 0 0 20px 0;
}

.FinanceIndex .itemsList {
    width: 97%;
    margin: 0 auto;
    background: white;
    padding: 30px 20px 20px;
}

.FinanceIndex .itemsList .removeBtn {
    background: none;
    border: none;
    color: rgb(185, 185, 185);
    padding: 10px 0 0 0;
    font-size: 16px;
}

.FinanceIndex .itemsList .removeBtn:hover,
.FinanceIndex .itemsList .removeBtn:focus
{
    color: black;
}


.FinanceIndex .SumAll {
    width: 100%;
    margin: 20px 0 0 0;
}

.FinanceIndex .SumAll ul {
    width: 300px;
    float: left;
    text-align: right;
}

.FinanceIndex .SumAll ul li {
    margin: 0 0 10px 0;
    text-align: right;
}

.FinanceIndex .SumAll ul li .inputPrependLabel span {
    background: whitesmoke;
    border: none;
    font-size: 19px;
    padding: 5px 0 0 5px;
}

.FinanceIndex .SumAll ul li input {
    font-size: 19px;
}

.FinanceIndex .SumAll ul li span.valueShow {
    float: left;
    width: 156px;
}

.FinanceIndex .SumAll ul li.superTotal {
    background: #2f4155;
    color: white;
    padding: 5px 10px 5px 0;
}

.FinanceIndex .PayOptions .Checks {
    margin: 40px 0 0 0;
}

.FinanceIndex .PayOptions .Checks h3,
.FinanceIndex .PayOptions .creditCard h3
{
    font-size: 20px;
    margin: 0 0 10px 0;
}

.FinanceIndex .PayOptions .Checks .checksList  {

    margin: 20px 0 0 0;
    background: white;
    padding: 20px 20px;

}

.FinanceIndex .PayOptions .Checks .checksList h4 {
    font-size: 18px;
    margin: 0 0 20px 0;
    
}

.FinanceIndex .PayOptions .Checks .divBoostrap {
    margin-bottom: 20px;
}

.FinanceIndex .PayOptions .Checks .checksList li {
    margin: 0 0 10px 0;
    font-size: 16px;
    border-bottom: 1px solid rgb(219, 219, 219);
    padding: 0 0 10px 0;
}

.FinanceIndex .PayOptions .Checks .checksList ul li button {
    margin: 0 10px 0 0;
    padding: 2px 5px;
    
} 

.FinanceIndex .PayOptions .Checks .checksList ul li button i {
    font-size: 14px;
}

.FinanceIndex .PayOptions .Checks .checksList .sendChecks {
    font-size: 18px;
}

.FinanceIndex .config {
    border-top: 1px solid rgb(219, 219, 219);
    padding: 40px 0 0 0;
    margin: 80px 0 0 0;
    text-align: center;
}

.FinanceIndex .config .buttonCont a {

    width: 300px;
    margin: 0 auto 20px;
    display: block;

}

.FinanceIndex .creditCard {
    margin: 40px 0 0 0;
}

.FinanceIndex .creditCard .divBoostrap,
.FinanceIndex .cashInputs .divBoostrap
{
    margin: 0px 0 20px 0;
}


.ShowInvoice .top {
    text-align: center;
}

.ShowInvoice .top h2 {
    font-size: 24px;
    color: #2f4155;
    
}

.ShowInvoice .top h2 strong {
    font-weight: bold;
    display: block;
    font-size: 28px;
    margin: 0 0px 10px 0;
}


.ShowInvoice .top .dateShow {
    text-align: left;
}

.ShowInvoice .top header {
    text-align: right;
}

.ShowInvoice .top header ul {
    margin: 0 0 20px 0;
}

.ShowInvoice .top header ul li {
    margin: 0 0 5px 0;
}

.ShowInvoice .details {
    background: white;
    padding: 20px;
}

.ShowInvoice .details ul li {
    text-align: right;
    
}

.ShowInvoice .details ul li strong {
    text-align: right;
    width: 300px;
    display: inline-block;
}

.ShowInvoice .details ul li span {
    display: inline-block;
    margin: 0 20px 0 0;
    text-align: right;
    width: 200px;
}

.FinanceIndex h2.insertOk {
    color: green;
    width: 100%;
    clear: both;
}

.FinanceIndex .ShowPaysCont
{
    border-bottom: 1px solid rgb(211, 211, 211);
    padding: 0 0 20px 0;
    margin: 0 0 50px 0;
}

.FinanceIndex .ShowPaysCont .invoiceBuild {
    text-align: left;
}

.FinanceIndex .ShowPaysCont header p {
    font-size: 20px;
    background: white;
    text-align: center;
    padding: 10px 0;
}

.FinanceIndex .ShowPaysCont header p span {
    margin-left: 10px;
    display: inline-block;
    direction: ltr;
}

.FinanceIndex .ShowPaysCont header p span.redClass {
    color: red;
    font-weight: bold;
}

.FinanceIndex .ShowPaysCont .paysItems {
    margin: 20px 0 0px;
}

.FinanceIndex .ShowPaysCont .paysItems li {
    margin: 0 0 10px 0;
}

.FinanceFirstConector .firsButton {
    padding: 50px 0 0 0;
}

.FinanceFirstConector .InvoiceList {
    margin: 20px 0 0 0;
}


.modalInvoices .buttonsCont,
.FinanceFirstConector .InvoiceList .buttonsCont {
    padding: 0 10px 0 0;
    display: inline-block;
}

.modalInvoices .buttonsCont {
    float: left;
}



.modalInvoices .buttonsCont a,
.modalInvoices .buttonsCont button,
.FinanceFirstConector .InvoiceList .buttonsCont a,
.FinanceFirstConector .InvoiceList .buttonsCont button
{
    margin: 0 0 0 0px;
    font-family: "fb_regular","helvetica","arial";

}

.FinanceFirstConector .InvoiceList .buttonsCont a,
.modalInvoices .buttonsCont a,
.FinanceFirstConector .InvoiceList .buttonsCont button,
.modalInvoices .buttonsCont button
{
    
    width: 100%;
    margin: 0 0px 10px 10px;
}

.modalInvoices .buttonsCont button:hover,
.modalInvoices .buttonsCont button:focus,
.FinanceFirstConector .InvoiceList .buttonsCont button:hover,
.FinanceFirstConector .InvoiceList .buttonsCont button:focus,
.modalInvoices .buttonsCont a:hover,
.modalInvoices .buttonsCont a:focus,
.FinanceFirstConector .InvoiceList .buttonsCont a:hover,
.FinanceFirstConector .InvoiceList .buttonsCont a:focus

{
    background: grey;
    color: white;
}


.buttonsCont .DropdownButtonsDownload,
.buttonsCont .doPaysForReceipt
{
    vertical-align: -webkit-baseline-middle;
    display: inline-block;
    margin: 0 0 0 10px;
}


.buttonsCont .doPaysForReceipt {
    width: 150px !important;
    margin: 0 0px 10px 0 !important;
}


.DropdownButtonsDownload .show {
    padding: 10px 10px;
    min-width: 200px;
}

.DropdownButtonsDownload .dropdown-toggle::after {
    margin-right: 10px;
}

.FinanceFirstConector .InvoiceList ul li {
    font-size: 16px;
    margin: 0 0 10px 0;
    padding: 2px 0;
}

.FinanceFirstConector .screens {
    padding: 20px 0 0 0;
}

.FinanceFirstConector .InvoiceList a.toPdf {
    margin: 0 20px 0 0;

}

.modalInvoices .editInvoiceBtn, .modalInvoices a {

    display: inline-block;
    width: 150px;
    margin: 0 10px 0 0;
    
}

.modalInvoices a:hover,
.modalInvoices a:focus
{
    color: black;
}


.FinanceFirstConector .InvoiceList .editInvoiceBtn {
    margin: 0 20px 0 0;
    display: inline-block;
    width: 160px;
}
.modalInvoices .editInvoiceBtn:hover,
.modalInvoices .editInvoiceBtn:focus,
.FinanceFirstConector .InvoiceList .editInvoiceBtn:hover,
.FinanceFirstConector .InvoiceList .editInvoiceBtn:focus {
    background-color: rgb(245, 245, 245);
}

.BuiltOnlyMasInvoice {
    padding: 50px 0 0 0;
    text-align: center;
}


.modalErrors.PaysError h2 {
    text-align: center;
    color: red;
    font-size: 30px;
}

.modalErrors.PaysError .butoonsCont {
    padding: 20px 0 0 0;
    text-align: center;
}


.ShowPaysCont .showPayDelette {
    padding: 5px;
    margin: 0 0 0 10px;
    font-size: 12px;
    border: none;
}

.modalMails.modal .modal-body {
    min-height: 650px;
    padding: 50px 60px;
}

.modalMails .sendMailForm {
    text-align: center;
}

.modalMails .sendMailForm label {
    text-align: right;
    width: 100%;
    margin: 0 0 10px 0;
}

.modalMails .sendMailForm .customInput {
    margin: 0 0 20px 0;
}

.modalMails .sendMailForm h2 {
    font-size: 26px;
}

.Wsign .selectCont {
    padding: 30px 20% 20px;
}

.Wsign .selectCont .alert {
    margin: 20px 0 0 0;
    text-align: center;
}

.Wsign .selectCont .alert a {
    margin: 0;
}

.WsignIndex .contSelect {
    padding: 30px 20px 20px;
}


.Wsign .StatusDocs {
    /* background: white; */
    font-size: 17px;
    padding: 0 20px;
}

.Wsign .StatusDocs table .boldClass td {
    background: white;
    font-family: "fb_regular","helvetica","arial";
}

.Wsign .noDocuments {
    font-size: 18px;
}

.StatusDocs table a.action {
    text-decoration: none;
}

.StatusDocs table a.action:hover,
.StatusDocs table a.action:focus {

    text-decoration: underline;

}

.StatusDocs table a.action,
.StatusDocs table a.action span {
    color: black
}

.StatusDocs table a.action .green {
    color: red;
    font-family: "fb_regular","helvetica","arial";
}



.ShareDropdown .dropdown-menu .btn {
    width: 100%;
    min-width: 200px;
    font-family: "fb_regular","helvetica","arial";
    font-size: 16px;
}

.ShareDropdown .dropdown-menu .btn:hover,
.ShareDropdown .dropdown-menu .btn:focus {
    background: #359ee5;
    color: white;  
}

.h2Ok {
    color: green;
}

.modalMails .h2Ok {
    margin: 20px 0;
    text-align: center;
}

.BtnMoneyReturnCard {
    display: inline-block;
    width: 120px !important;
    margin: 0 !important;
}

.modalAllComments.modalInvoices.modalMoneyBack  .modal-body ul {
    height: inherit;
    overflow: inherit;
    margin: 0 0 50px 0;
}

.modalAllComments.modalInvoices.modalMoneyBack .creditCard {
    padding: 0 10px 20px;
}

.modalMoneyBack .creditCard .returnMoneyCardValues {
    padding: 0 5px;
}

.modalMoneyBack .creditCard .returnMoneyCardValues .divBoostrap {
    margin: 0 0 20px 0;
}

.modalMoneyBack .h2Ok {
    margin: 20px 0 50px 0;
    text-align: center;
}




.tableDefault {
    width: 100%;
    display: table;
    /* background: white; */
    text-align: center;
}

.tableDefault .trTable {
    display: table-row;
}

.tableDefault .trTable.titlesTr div {
    
    background: white;
    font-family: "fb_regular","helvetica","arial";
    line-height: 1.2;
    font-size: 17px;
    color:grey;
    padding: 5px 0 ;
    border-left: 1px solid #bebebe;
    background: #e9ecef;
    
}


.tableDefault .trTable.titlesTr div:first-child {
    border-radius: 0 5px 5px 0;
}

.tableDefault .trTable.titlesTr div:last-child {
    border-radius: 5px 0 0 5px;
}

.tableDefault .trTable.titlesTr div:last-child {
    border-left: 0;
}

.tableDefault .trTable .tdTable {
    display: table-cell;
    vertical-align: middle;
}

.tableDefault .trTable  .vSpace {
    padding: 4px 0;
}

.tableDefault .trTable.contentTds > div {
    display: table-cell;
    vertical-align: middle;
    background: white;
    /* border-bottom: 1px solid rgb(204, 204, 204); */
    
    
}

.tableDefault .trTable.contentTds > div span {
    /* background: white; */
    margin: 0 auto;
    width: 90%;
    display: block;
    /* border: 1px solid #ced4da; */
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 0;
}

.tableDefault .trTable.contentTds.spaceTr > div {
    background: transparent;
    /* border-bottom:none; */
}

.tableDefault .trTable.contentTds > div:last-child {
    border-left: 0;
}


.tableDefault input.changeMana {
    padding-left: 50px;
}

.tableDefault .smallBtnBlack {
    margin: -30px 0 0 0;
}



.ReportsSection {
    margin: 40px 0 0 0;
    padding: 0 0%;
}


.ReportsSection .row2 {
    padding-top: 38px;
}

.ReportsSection .row2 > div:nth-child(2) {
    margin: 25px 0 0 0;
}





.ReportsSection .tableResults {
    margin: 0px 0 0 0;
    padding: 0px 0 0 0;
    width: 100%;
    max-width: 1500px;
    height: 400px;
    overflow: auto;
    background: white;
    box-shadow: 0px 4px 5px -1px rgb(0 0 0 / 20%);
    position: relative;
    /* overflow-x: auto;
    overflow-y: auto; */
}

.ReportsSection .tableResults .overFlow {
    width: 2500px;
    
    
}

.ReportsSection .tableResults .overFlow table {
    width: 100%;
}

.ReportsSection .tableResults th {
    border-left: 1px solid white;
    text-align: center;
    line-height: 1.2;
    font-size: 16px;
    padding: 5px;
    background: #2f4155;
    color: white;
    font-weight:normal;
}

.ReportsSection .tableResults tr td {
    border-left: 1px solid #b9b9b9;
    text-align: center;
    padding: 5px 5px;
    font-size: 16px;
}

.ReportsSection .tableResults tr:nth-child(even) td {
    background: rgb(214, 214, 214);
}

.excelBtn {
    
    top: 0;
    border: none;
    margin: 0 -10px 10px 0;
    color: #1f6e43;
    height: 50px;
    background: none;
}

.excelBtn i {
    font-size: 30px;
}

.excelBtn:hover i {
    font-size: 35px;
}